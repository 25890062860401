import React from 'react';
import { TransformComponent } from 'react-zoom-pan-pinch';

import { InlineSpinner } from '@/components/InlineSpinner';
import { FileLayout } from '../FileLayout';
import { FileDriver } from '.';

const ImageDriver: FileDriver = ({ filePath, isLoading, onLoaded }) => (
  <FileLayout filePath={filePath} isLoading={isLoading}>
    {isLoading && <InlineSpinner />}

    <TransformComponent>
      <img alt="" src={filePath} onLoad={onLoaded} />
    </TransformComponent>
  </FileLayout>
);

export default ImageDriver;
